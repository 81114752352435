
.socials {
    position: absolute;
    top: 38%;
    right: 4%;
}

.profile {
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    letter-spacing: 6px;
}

.avatar-box {
    margin-top: 5vh;
}

.avatar-box img {
    max-width: 30px;
    margin-right: 4.85vw;
}

@media (max-width: 418px) {
    .avatar-box {
        margin-top: 2vh;
    }

    .socials {
        top: 20%;
        padding-left: 4%;
        right: 0;
        text-align: center;
    }
}