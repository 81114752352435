
.about-me-home {
    display: flex;
    position: relative;
    margin-left: 2em;
    margin-bottom: 2em;
    margin-right: 2em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
    text-align: justify;
}

.about-me-home p {
    font-size: large;
}

.about-me-heading {
    margin-left: 1.4em;
}

.text {
    padding: 1em;
    width: 50%;
}

.image {
    position: relative;
    padding: 1em;
    width: 50%;
}

.image img {
    max-width: 100%;
    height: auto;
    float: right;
}


@media (max-width: 1164px) {
    .about-me-home {
        display: block;
    }

    .text {
        width: 100% 
    }

    .image {
        width: 100%; 
    }

    .image img {
        float: none;
    }
}