.footer {
    position: relative;
    background-color: #1f2629;
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row;
    max-height: 10vh;
}

.avatar-box-footer {
    position: relative;
    width: 50%;
    /* left: 5%; */
}

.email {
    position: relative;
    padding-top: 1%;
    color: white;
    font-size: 1.2em;
    width: 50%;
    text-align: center;
}

.avatar-box-footer img {
    max-width: 30px;
    margin-right: 4.85vw;  
    padding-top: 1.5%;
}


@media (max-width: 818px) {
    .footer {
        display: inline-block;
    }
    
    .avatar-box-footer img { 
        display: none;
    }

    .email {
        width: 100%;
        text-align: center;
    }
}