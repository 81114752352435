
#imgbox {
    position: relative;
    width: 100%;
}

#imgbox.home img {
    width: 100%;
}

.home img {
    max-width: 100%;
    max-height: 100vh;
    height: auto;
}

